export default class RatingAgency {
	constructor() {
		this.id = null
		this.title = null
		this.min = null
		this.max = null
		this.ratingType = null
		this.mandatory = null
		this.default = null
	}
}