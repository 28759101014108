export default class WineSupplier {
	constructor() {
		this.id = null
		this.title = null
		this.address = null
		this.venues = []
		this.phone = null
		this.email = null
		this.notes = null
		this.isGeneral = null
	}
}