export default class Inventory {
    constructor() {
        this.id = null;
        this.name = null;
        this.venue = null;
        this.inventoryType = null;
        this.startDate = null;
        this.transferDate = null;
        this.inventoryState = null;
        this.inventoryScope = {
            valueName: null,
            scope: null
        };
        this.inventoryVenueWines = [];
        this.inventory_users = [];
    }
}