import Region from './Region'
import Land from './Land'

export default class Winery {
	constructor() {
		this.id = null
		this.title = null
		this.address = null
		this.wines = []
		this.region = new Region()
		this.land = new Land()
		this.image = null
	}
}