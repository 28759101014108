import Land from './Land'

export default class Region {
	constructor() {
		this.id = null
		this.title = null
		this.wines = []
		this.land = new Land()
		this.curated = false
		this.localeKey = null
	}
}