export default class InventoryVenueWine{
    constructor() {
        this.id = null;
        this.actualAmountFloat = null;
        this.venueWine = null;
        this.inventoryVenueWineState = null;
        this.sellingPrice = null;
        this.targetAmountFloat = null;
        this.duplicate = null;
    }
}