import TaxRate from "./TaxRate"

export default class Venue {
	constructor() {
		this.id = null
		this.title = null
		this.users = []
		this.address = null
		this.venueWines = []
		this.image = null
		this.playlists = []
		this.wineSuppliers = []
		this.smartLists = []
		this.glassAmountPerBottle = []
		this.currency = null
		this.reports = []
		this.wines = []
		this.revenueOptimizePercentLimit = null
		this.wineries = []
		this.taxRate = new TaxRate()
	}
}